<template>
  <div>
    <the-toolbar title="Histórico de alterações" />
    <div class="col-md-8 col-11 mx-auto">
      <div v-if="loading" class="d-flex justify-center mt-10 pa-10">
        <v-progress-circular color="primary" indeterminate></v-progress-circular>
      </div>
      <div v-else-if="data.length > 0">
        <v-card v-for="(item, index) in data" :key="index" class="card-notificacao pa-4 my-4 cursor-pointer" @click="verProposta(item.internalId, item.guidProposta, item.tipo)">
          <v-chip label :color="item.cor" class="label-novo white--text" v-if="!item.visto"> novo! </v-chip>
          <v-sheet
            class="barra"
            :color="item.cor"
            elevation="1"
            height="168"
            min-height="100%"
            width="8"
            rounded
            ></v-sheet>
          <div class="d-flex justify-space-between align-center">
              <div>
                <v-card-title class="text-subtitle-2 black--text text-uppercase font-weight-medium">Proposta nº {{ item.nrProposta }}</v-card-title>
              <v-card-text>
                <strong>{{ item.nomeCliente }}</strong><br/>
                data: {{ item.dataAtualizacao }} <br/>
                motivo: {{ item.descricao }}
              </v-card-text>
            </div>
            <v-btn text :color="item.cor" class="white--text elevation-0 px-4 py-1"
            >
            <div v-if="editando === item.internalId">
              <v-progress-circular :color="item.cor" indeterminate></v-progress-circular>
            </div>
            <v-icon v-else>mdi-chevron-right</v-icon></v-btn>
          </div>
        </v-card>
      </div>
      <div v-else>
        <div class="mt-8">
          <div class="text-center text-h6 primary--text">Não há alterações.</div>
          <div class="text-center mt-2">Nenhuma alteração disponível no momento.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import relatoriosService from '@/services/relatoriosService';
import TheToolbar from "@/components/TheToolbar.vue";

export default {
  components: { TheToolbar },
  data() {
    return {
      editando: null,
      data: [],
      loading: false
    };
  },
  created() {
    this.getNotificacoes()
  },
  methods: {
    async verProposta(internalId, guid, tipo) {
      this.editando = internalId;
      try {
        await relatoriosService.alteracaoVisualizada({ internalId, tipo });
        this.$router.push({ name: 'areaLogada.dadosProposta', params: { guid } });
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: error.message,
        });
        this.editando = null;
      }
    },
    async getNotificacoes() {
      this.loadingNotificacoe = true;
      try {
        this.data = await relatoriosService.alteracoesPropostas();
        this.data.forEach((notificacao) => {
          notificacao.cor = this.getColor(notificacao.statusProposta);
        });
        this.loadingNotificacoe = false;
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message,
        });
      }
    },
    getColor(status) {
      switch (status) {
      case "contrato_assinado":
      case "pronta_transmissao":
        return "blue";
      case "devolvida":
        return "deep-orange";
      case "implantada":
        return "green";
      case "finalizada":
        return "lime";
      default:
        return "grey";
      }
    }
  },
};
</script>

<style lang="scss">
.card-notificacao {
  position: relative;
  .label-novo {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .barra {
    position: absolute;
    top: 0;
    left: 0;
  }
}

</style>
