import api from '@/services/api';
import AppError from '@/utils/appError';

const resource = 'relatorios';
const visaoGeral = async ({ dataInicial, dataFinal }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_RELATORIOS_API_KEY } };
    const { data } = await api.get(`${resource}/visaoGeral${dataInicial && dataFinal ? `?dataInicial=${dataInicial}&dataFinal=${dataFinal}` : "" }`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};
const getSobre = async ({ tipoConteudo }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_RELATORIOS_API_KEY } };
    const { data } = await api.get(`${resource}/visaoGeralSobre?tipoConteudo=${tipoConteudo}`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};
const alteracoesPropostas = async () => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_RELATORIOS_API_KEY } };
    const { data } = await api.get(`${resource}/alteracoesProposta`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};
const alteracaoVisualizada = async ({ tipo, internalId }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_RELATORIOS_API_KEY } };
    const data = await api.put(`${resource}/vistoAlteracaoProposta`, {
      tipo,
      internalId,
    }, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};
const logAcesso = async ({ tela, modulo }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_RELATORIOS_API_KEY } };
    const data = await api.post(`${resource}/logAcesso`, { tela, modulo }, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

export default {
  visaoGeral,
  getSobre,
  alteracoesPropostas,
  alteracaoVisualizada,
  logAcesso,
};
